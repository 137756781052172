// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
//   background: transparent;
// }

// ::-webkit-scrollbar-button {
//   display: none;
// }

// ::-webkit-scrollbar-corner {
//   background: transparent;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 6px;
//   background: #c4c4c4;
// }

html {
  --main-card-height: calc(100vh - 94px); // 100vh - 55px - 40px - 12px
  --main-card-box-height: calc(
    100vh - 123px
  ); // 100vh - 55px - 40px - 12px - 16px
  --main-page-body-height: calc(var(--main-card-height));
  --main-table-height: calc(var(--main-page-body-height) - 132px);
  --main-table-width: calc(100vw - 220px);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #eee !important;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: #ccc !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #ccc !important;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.page-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  height: var(--main-page-body-height);
  .search-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: space-between;
    align-items: flex-end;
  }
  .table-box {
    // height: var(--main-table-height);
    overflow-y: auto;
    flex: auto;
    margin: 10px 0;
  }
  .pagination {
    text-align: right;
  }
}
